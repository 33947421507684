import { useEffect } from "react";
import "./styles/tailwind.css";
import "./styles/globals.css";
import { Navbar } from "./components";
import { Hero } from "./containers";

function App() {
  useEffect(() => {
    (function (w, d, s, u) {
      w.gbwawc = {
        url: u,
        options: {
          waId: "+918297741115",
          siteName: "NotBot",
          siteTag: "WA Chatbots & Automations",
          siteLogo: "https://i.ibb.co/MhJnxXg/bot.png",
          widgetPosition: "RIGHT",
          triggerMessage: "Try Live Chatbot!!",
          welcomeMessage:
            "Hi! Welcome to NotBot. \nI make Best Whatsapp API based Chatbots and Automations.  ",
          brandColor: "#25D366",
          messageText: "I am looking for:",
          replyOptions: [
            "Whatsapp Business API",
            "Whatsapp Chatbots",
            "Whatsapp Automations",
          ],
        },
      };
      var h = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
      j.async = true;
      j.src = u + "/whatsapp-widget.min.js?_=" + Math.random();
      h.parentNode.insertBefore(j, h);
    })(window, document, "script", "https://waw.gallabox.com");
  }, []);

  return (
    <>
      <Navbar />
      <Hero />
    </>
  );
}

export default App;
