import React from "react";
import whastAppLogo from "../assets/icons/logos_whatsapp.svg";
import { Form } from "../components";

const Hero = () => {
  return (
    <div className="w-full h-[100vh] custom-bg ">
      <div className="flex flex-col items-center justify-center h-[350px] lg:gap-[40px] ">
        <div className="flex items-center justify-center gap-2 mt-[-100px] lg:mt-[0px] ">
          <h1 className="text-xl text-white lg:text-4xl">
            Create WhatsApp Links
          </h1>
          <img
            src={whastAppLogo}
            alt="whatsApp logo"
            className="w-[20px] lg:w-[40px]"
          />
        </div>
        <p className="text-sm font-bold text-center text-white lg:text-2xl text-opacity-80">
          Walink is the most popular WhatsApp link generator <br />
          worldwide 🌎
        </p>
      </div>

      {/* Send Messages Section */}
      <div className="px-[20px] lg:px-[40px] w-full mt-[-130px] lg:mt-[-40px]">
        <h1 className="text-2xl font-semibold text-slate-800 ">
          Would you like to send a message!
        </h1>
        {/* Form Container */}
        <div className="border-blue-500 flex flex-col lg:flex-row mt-[10px]  lg:mt-[30px] items-center w-ful justify-between">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Hero;
