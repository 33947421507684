import React from "react";
import Logo from "../assets/logo_white.svg";

const Navbar = () => {
  return (
    <div className="h-[8vh] bg-white custom-nav flex items-center px-[40px]">
      <div>
        <img src={Logo} alt="Notbot" className="h-[35px]" />
      </div>
    </div>
  );
};

export default Navbar;
