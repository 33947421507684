import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";

const Form = (props) => {
  const [PhoneValue, setPhoneValue] = useState("");
  const [message, setMessage] = useState("");
  const [encodedState, setEncodedState] = useState(null);
  const [copied, setCopied] = useState(false);
  const [linkURL, setLinkURL] = useState("");

  const GenerateLink = (e) => {
    e.preventDefault();

    const encodedMessage = message.replace(/ /g, "%20");

    setEncodedState(encodedMessage);
  };

  console.log(PhoneValue);

  useEffect(() => {
    setLinkURL(`https://wa.me/${PhoneValue}?text=${encodedState}`);
  }, [PhoneValue, encodedState]);

  return (
    <>
      <div className="custom-form p-[10px] lg:p-[30px] w-[350px] lg:w-[400px]">
        <form className="flex flex-col items-center h-[250px] border p-[20px] rounded  ">
          <div className="">
            <p className="text-xs text-gray-600 text-opacity-80">
              Enter your phone number
            </p>
            <PhoneInput
              specialLabel={""}
              value={PhoneValue}
              placeholder="Enter Phone Number"
              onChange={(PhoneValue) => setPhoneValue(PhoneValue)}
              country={"in"}
              inputStyle={{
                placeholder: "+91 XX XXX XXXX",
                borderColor: props.touched && props.error && "red",
              }}
            />
          </div>
          <textarea
            name="message"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message"
            rows="5"
            className="border border-slate-300 hover:drop-shadow-xl transition-all transform ease-in-out duration-300  rounded mt-[10px] p-[10px] w-[300px] caret-blue-600"
          />

          <button
            className="bg-green-500 w-[300px] rounded-md py-[5px] mt-[10px] text-white hover:bg-green-600 transition-all transform ease-in-out duration-300 flex justify-center items-center"
            onClick={GenerateLink}
          >
            Generate Link
          </button>
        </form>
      </div>

      {encodedState === null ? (
        ""
      ) : (
        <div className="w-[70%]">
          <div className="flex justify-center items-center gap-[50px]">
            <a
              href={`https://wa.me/${PhoneValue}?text=${encodedState}`}
              className="text-black bg-green-500 mt-[20px] h-[80px] flex justify-center items-center lg:mt-[0px] p-[10px] lg:p-[30px] shadow-lg transition-all ease-in-out duration-300 transform hover:shadow-2xl rounded-lg cursor-pointer glassmorphism hover:text-green-500 ring ring-green-300 ring-offset-4 ring-offset-green-200 hover:scale-110"
            >
              https://wa.me/{PhoneValue}?text={encodedState}
            </a>
            <div>
              <QRCode value={`https://wa.me/${PhoneValue}?text=${encodedState}`} size={200} bgColor="#ffffff" fgColor="#25D366" />
            </div>
          </div>

          <div className="flex justify-center">
            {copied === true ? (
              <span className="mt-[30px] bg-red-200 text-red-500 shadow-lg rounded-lg p-[10px] transition-all duration-300 ease-in-out transform ">
                Copied
              </span>
            ) : (
              <CopyToClipboard text={linkURL} onCopy={() => setCopied(true)}>
                <button className="mt-[30px] bg-green-500 text-white rounded-lg p-[10px] transition-all duration-300 ease-in-out transform hover:scale-95 hover:shadow-md">
                  Copy to clipboard
                </button>
              </CopyToClipboard>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Form;
